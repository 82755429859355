import { find } from "lodash";

import servicesAPI from "api/services";

import { createAsyncServerThunk } from "operations/redux";

export const loadRegionServer = createAsyncServerThunk<
  Region,
  Region["letterId"] | void
>(
  "regions/loadRegionServer",
  async letterId => {
    const { states, ...region } = await servicesAPI.fetchRegion(letterId);

    return {
      ...region,
      states: states.reduce(
        (acc, item) => Object.assign(acc, { [item.letterId]: item }),
        {},
      ),
    };
  },
  {
    selectResult({ regions }, letterId: Region["letterId"]) {
      return (
        regions.data[letterId] ||
        find(regions.data, ({ states }) => letterId in states)?.states[letterId]
      );
    },
  },
);
