import servicesAPI from "api/services";

import { createAppAsyncThunk } from "store/duck/types";

import { RegionsDictionary } from "./duck/types";

export const loadRegions = createAppAsyncThunk(
  "regions/loadRegions",
  async (_, { getState }) => {
    const {
      regions: { isLoaded, data },
    } = getState();

    if (isLoaded) {
      return data;
    }

    const regions = await servicesAPI.fetchAllRegions();
    const result: RegionsDictionary = {};
    for (const region of regions) {
      result[region.letterId] = {
        ...region,
        states: region.states.reduce(
          (acc, region) => Object.assign(acc, { [region.letterId]: region }),
          {},
        ),
      };
    }

    return result;
  },
);
