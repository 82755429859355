import { instance } from "./instances/coindisco";

interface RegionDTO {
  id: number;
  kind: string;
  name: string;
  letterId: string;
  alpha2Code: string;
  alpha3Code: string;
  icon: string;
  parentRegion: RegionDTO | null;
  default: boolean;
  childExists: boolean;
}

interface RegionWithStatesDTO extends RegionDTO {
  states: RegionDTO[];
  parentRegion: null;
}

const servicesAPI = new (class {
  public async fetchAllRegions(): Promise<RegionWithStatesDTO[]> {
    const { data } = await instance.get("service-integration/regions/all/");

    return data;
  }

  public async fetchPaymentMethodsForRegion(
    regionId: number,
  ): Promise<PaymentOption[]> {
    const { data } = await instance.get(
      `service-integration/regions/${regionId}/payment-methods/`,
    );

    return data;
  }

  public async fetchCurrenciesForRegion(
    regionId: number,
  ): Promise<CurrencyWithDefault[]> {
    const { data } = await instance.get(
      `service-integration/regions/${regionId}/currencies/`,
    );

    return data;
  }

  public async fetchRegion(
    regionLetterId: Region["letterId"] | void,
  ): Promise<RegionWithStatesDTO> {
    const { data } = await instance.post("service-integration/regions/my/", {
      regionLetterId,
    });

    return data;
  }
})();

export default servicesAPI;
